import React, { useState, useEffect } from 'react';
// import classNames from 'classnames';
import { Task20, Forum20, AddComment20, SubtractAlt20, Edit20 } from '@carbon/icons-react';
import { List, Map } from 'immutable';
import { format, parseISO } from 'date-fns';
import { Textarea, Select } from './TextInputs';
import { Button } from './Buttons';
import { highlight } from './Blade';
import { Avatar } from './Nav';

const defaultState = Map({ type: 'Comment', text: '' });

const noteTypeMap = {
  COMMENT: 'Comment',
  FINDINGS: 'Findings',
};
const noteDescMap = {
  Comment: 'COMMENT',
  Findings: 'FINDINGS',
};

const urlRegex = /(https?:\/\/[^\s]+)/g;

export const Note = ({ state, dispatch, note, onEdit, editingDisabled }) => {
  const noteUuid = note.get('noteUuid');
  const type = note.get('noteType');
  const author = note.get('createdBy');
  const text = note.get('text');
  const date = parseISO(note.get('createdAt'));
  const nodeUuid = note.get('nodeUuid');

  function linkify(text) {
    return text.split(urlRegex).map(part => {
      if (part.match(urlRegex)) {
        return (
          <a key={part} className={'white'} target="_blank" rel="noopener noreferrer" href={part}>
            {part}
          </a>
        );
      }
      return part;
    });
  }

  return (
    <div
      className={'pa3 mb3 bg-black relative'}
      onMouseEnter={() => {
        highlight(dispatch, nodeUuid);
      }}
    >
      <div
        onClick={() => {
          if (editingDisabled) return;

          return dispatch(Map({ type: 'DELETE_NOTE', noteUuid }));
        }}
        data-tip="Delete"
        className="absolute flex top-0 right-0 pa2 items-center red dim pointer"
      >
        <SubtractAlt20 />
      </div>
      <div onClick={onEdit} data-tip="Edit" className="absolute flex bottom-0 right-0 pa2 items-center dim pointer">
        <Edit20 />
      </div>
      <div className={'mb3 f6 white flex items-center'}>
        {type === 'COMMENT' ? <Forum20 /> : <Task20 />}
        <div className={'fw6 ml2 f6 white'}>{noteTypeMap[type]}</div>
      </div>
      <div className={'lh-title'}>{linkify(text)}</div>
      <div className={'f6 mt2 light-gray'}>{format(date, 'dd MMM yyyy')}</div>
      <div className={'mt3 f6 white'}>
        <Avatar height={40} state={state} dispatch={dispatch} username={author} />
      </div>
    </div>
  );
};

export const EditNote = ({ state, disabled, dispatch, note, onEdit, onCancel }) => {
  const [model, setModel] = useState(defaultState);
  const treeUuid = state.getIn(['tree', 'uuid']);
  const noteUuid = note.get('noteUuid');

  useEffect(() => {
    if (note) {
      const text = note.get('text');
      const noteType = noteTypeMap[note.get('noteType')];
      setModel(m => m.set('text', text).set('noteType', noteType));
    } else {
      setModel(defaultState);
    }
  }, [setModel, note]);

  const editNote = () => {
    const text = model.get('text');
    const noteType = noteDescMap[model.get('noteType')];

    if (disabled) return;

    if (!text) {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Note message cannot be empty.',
          style: 'ERROR',
        }),
      );
    } else if (!noteType || (noteType && noteType.toLowerCase() === 'Select'.toLowerCase())) {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Select any one item from note type.',
          style: 'ERROR',
        }),
      );
    } else {
      dispatch(
        Map({
          type: 'EDIT_NOTE',
          treeUuid,
          noteUuid,
          text,
          noteType,
        }),
      );
      onEdit();
    }
  };

  return (
    <div>
      <div className={'mv2'}>
        <Select
          title="Type"
          options={List(['Select'].concat(Object.keys(noteDescMap)))}
          inverse
          value={model.get('noteType', 'Select')}
          onChange={e => setModel(model.set('noteType', e.target.value))}
        />
      </div>
      <div className={'mv2'}>
        <Textarea
          title={'Message'}
          value={model.get('text', '')}
          onChange={e => setModel(model.set('text', e.target.value))}
          inverse
        />
      </div>
      <div className={'flex items-center'}>
        <Button
          text="Save Note"
          icon={<AddComment20 className={'white fill--white'} />}
          small
          className={'w-100'}
          onClick={editNote}
        />
      </div>
      <div className="mv2">
        <Button text="Cancel" icon={<div />} small className={'w-100'} theme="secondary" onClick={onCancel} />
      </div>
    </div>
  );
};

export const AddNote = ({ state, dispatch, editingDisabled }) => {
  const [model, setModel] = useState(defaultState);
  const treeUuid = state.getIn(['tree', 'uuid']);
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());
  const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
  const nodeUuid = node.get('oid');

  const addNote = () => {
    const text = model.get('text');
    const noteType = model.get('noteType');
    if (!text) {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Note message cannot be empty.',
          style: 'ERROR',
        }),
      );
    } else if (!noteType || (noteType && noteType.toLowerCase() === 'Select'.toLowerCase())) {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Select any one item from note type.',
          style: 'ERROR',
        }),
      );
    } else {
      dispatch(
        Map({
          type: 'ADD_NOTE',
          treeUuid,
          text,
          nodeUuid,
          noteType: noteDescMap[noteType],
          path: path,
        }),
      );
      setModel(defaultState);
    }
  };

  return (
    <div>
      <div className={'mv2'}>
        <Select
          title="Type"
          disabled={editingDisabled}
          options={List(['Select'].concat(Object.keys(noteDescMap)))}
          inverse
          value={model.get('noteType', 'Select')}
          onChange={e => {
            console.log(e.target.value);
            setModel(model.set('noteType', e.target.value));
          }}
        />
      </div>
      <div className={'mv2'}>
        <Textarea
          disabled={editingDisabled}
          title={'Message'}
          value={model.get('text', '')}
          onChange={e => setModel(model.set('text', e.target.value))}
          inverse
        />
      </div>
      <div className={'flex items-center'}>
        <Button
          disabled={editingDisabled}
          text="Save Note"
          icon={<AddComment20 className={'white fill--white'} />}
          small
          className={'w-100'}
          onClick={addNote}
        />
      </div>
    </div>
  );
};
