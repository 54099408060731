import { Launch16 } from '@carbon/icons-react';
import clsx from 'classnames';
import { Spinner, Popover } from 'components';

export const RevisionItem = ({
  time,
  author,
  onClick,
  onConfirm,
  onCancel,
  disabled = false,
  loading = false,
}: {
  time: string;
  author: string;
  disabled?: boolean;
  onClick?: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
}) => (
  <Popover>
    <Popover.Trigger onClick={onClick}>
      <button
        disabled={disabled || loading}
        className={clsx(
          'w-100 px3 hide-child bg-transparent flex items-center justify-start white hover-bg-white-10 br1 pointer pl3 pv1',
        )}
        style={{ minHeight: 28 }}
      >
        <span className="f6 mb0 mt0 truncate">
          {time} - {author}
        </span>
        {loading ? (
          <Spinner size={20} className="ml-auto" />
        ) : (
          <Launch16 color="white" className="child ml-auto" style={{ width: 12 }} />
        )}
      </button>
    </Popover.Trigger>
    <Popover.Content style={{ padding: '8px 12px' }} dark>
      <p className="mt0 mh0 fg--white-75 fw-medium mb1">Create new tree from this revision?</p>
      <div className="w-30 flex items-center justify-around">
        <Popover.Confirm onClick={onConfirm}>Yes</Popover.Confirm>
        <span className="fg--white-60 ph1">/</span>
        <Popover.Cancel className="unstyled fw-medium fg--white-55 hover-white" onClick={onCancel}>
          No
        </Popover.Cancel>
      </div>
    </Popover.Content>
  </Popover>
);
