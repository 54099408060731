import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { List, Map, Stack } from 'immutable';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import {
  WarningAlt24,
  ErrorOutline32,
  Undo24,
  Redo24,
  Flow24,
  Image24,
  Folder24,
  Cognitive24,
  RotateCounterclockwiseAlt24,
  RotateClockwiseAlt24,
  Catalog24,
  DocumentDownload24,
  Renew32,
  Add24,
  Subtract24,
  Hashtag24,
  ColorPalette24,
  Report24,
  Locked16,
  Warning16,
  Time24,
} from '@carbon/icons-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { _ } from 'utils';
import { RevisionHistory } from 'RevisionHistory';
import { PROACTTree } from './PROACT';
import { FiveWhysTree } from './FiveWhys';
import { FishboneTree } from './Fishbone';
import { NodePopover } from './NodePopover';
import { NodeInputPopover } from './NodeInputPopover';
import { TemplateOptions } from './TemplateOptions';
import { AttachmentOptions } from './AttachmentOptions';
import { NodeAttachmentOptions } from './NodeAttachmentOptions';
import { EventInfoOptions } from './EventInfoOptions';
import { /* Avatar, */ HomeNav } from './Nav';
import { TreeViewer } from './TreeViewer';
import { getAllTags, initDispatchedWS, getTreeCategories } from './Sync';

/*
const Presence = ({ state, dispatch }) => {
  const members = state.getIn(['tree', 'view', 'members'], List());
  const uuid = state.getIn(['tree', 'uuid']);
  const me = state.get('username');

  useEffect(() => {
    if (uuid) {
      dispatch(
        Map({
          type: 'PRESENCE',
          treeUuid: uuid,
        }),
      );
    }
  }, [uuid, dispatch]);

  return (
    <div className="fixed z-2 right-1 flex'} style={{ top: '4rem' }}>
      {members
        .filterNot(u => u === me)
        .map(u => (
          <div key={u} className="ml2 fadeIn'}>
            <Avatar
              state={state}
              dispatch={dispatch}
              username={u}
              height={48}
            />
          </div>
        ))}
    </div>
  );
};
*/

export const TreeEditorControlsButton = ({ className, icon, onClick, disabled, text }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className={classNames(className, 'fill--white pa2', {
        'not-allowed gray': disabled,
        'dim pointer': hover && !disabled,
        'fill--white': !disabled && !hover,
      })}
      data-tip={text}
      data-place="bottom"
      data-delay-show={250}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={e => {
        if (!disabled) {
          onClick(e);
        }
      }}
    >
      <div className="pa1">{icon}</div>
    </div>
  );
};
const TreeEditorControlsDivider = () => {
  return <div className="w-100 bg-white-30 mv2" style={{ height: '1px' }}></div>;
};

export const TreeEditorControls = ({ state, dispatch, isViewOnly }) => {
  const [model, setModel] = useState(Map());
  const treeUuid = state.getIn(['tree', 'uuid']);
  const empty =
    state.getIn(['tree', 'elements', 'children'], List()).size == 0 && !state.getIn(['tree', 'elements', 'text']);
  const name = state.getIn(['trees', treeUuid, 'title'], 'Untitled Analysis');

  const i18n = state.get('i18n');

  const onBlob = (err, blob) => {
    if (err) {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Unable to generate the image.',
          style: 'ERROR',
        }),
      );
    } else {
      const url = URL.createObjectURL(blob);
      const elem = (
        <a href={url} download={`${name}.png`}>
          Click here to download.
        </a>
      );

      setTimeout(() => dispatch(Map({ type: 'PNG_DELETE' })), 6000);

      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Your image is ready.',
          style: 'SUCCESS',
          action: {
            message: elem,
            onClick: () => null,
          },
        }),
      );
    }
  };

  const hasTemplates = !state.getIn(['tree', 'view', 'templateOptions'], List()).isEmpty();

  const orientation = state.getIn(['tree', 'view', 'layout', 'rankDir']);
  const contextual = state.getIn(['tree', 'view', 'contextualDrawer']);
  const history = useHistory();

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const username = state.get('username', '');

  return (
    <div
      className="z-1 fixed bg-black-80 white"
      style={{
        top: '4rem',
        left: contextual ? '400px' : '1rem',
      }}
    >
      <div className="flex flex-column justify-center items-center">
        <div
          className="relative"
          onMouseEnter={() => setModel(model.set('settings', true))}
          onMouseLeave={() => setModel(model.delete('settings'))}
        >
          <TreeEditorControlsButton
            onClick={() => null}
            icon={<ColorPalette24 />}
            text={_(i18n, 'Appearance Options')}
          />
          <div
            className={classNames('top-0 left-0 z-1 bg-black-80 bl b--white-30', {
              absolute: model.get('settings'),
              dn: !model.get('settings'),
            })}
            style={{ marginLeft: '48px' }}
          >
            <TreeEditorControlsButton
              disabled={isViewOnly}
              onClick={() => {
                const or = orientation === 'LR' ? 'TB' : 'LR';
                dispatch(Map({ type: 'ORIENTATION', orientation: or }));
              }}
              icon={orientation === 'LR' ? <RotateClockwiseAlt24 /> : <RotateCounterclockwiseAlt24 />}
              text={orientation === 'LR' ? _(i18n, 'Top-to-bottom') : _(i18n, 'Left-to-right')}
            />
            <TreeEditorControlsButton
              onClick={() => dispatch(Map({ type: 'RUN_LAYOUT' }))}
              icon={<Flow24 />}
              text={_(i18n, 'Organize')}
            />
            <TreeEditorControlsButton
              onClick={() => dispatch(Map({ type: 'TOGGLE_GRID' }))}
              icon={<Hashtag24 />}
              text={_(i18n, 'Toggle Grid')}
            />
            <TreeEditorControlsButton
              onClick={() => dispatch(Map({ type: 'FONT_SIZE_DOWN' }))}
              icon={<Subtract24 />}
              text={_(i18n, 'Decrease Font Size')}
            />
            <TreeEditorControlsButton
              onClick={() => dispatch(Map({ type: 'FONT_SIZE_UP' }))}
              icon={<Add24 />}
              text={_(i18n, 'Increase Font Size')}
            />
          </div>
        </div>
        <TreeEditorControlsDivider />
        <TreeEditorControlsButton
          disabled={state.getIn(['tree', 'view', 'undoStack'], Stack()).size === 0 || isViewOnly}
          onClick={() => dispatch(Map({ type: 'UNDO' }))}
          icon={<Undo24 />}
          text={_(i18n, 'Undo')}
        />
        <TreeEditorControlsButton
          disabled={state.getIn(['tree', 'view', 'redoStack'], Stack()).size === 0 || isViewOnly}
          onClick={() => dispatch(Map({ type: 'REDO' }))}
          icon={<Redo24 />}
          text={_(i18n, 'Redo')}
        />
        <TreeEditorControlsDivider />
        <TreeEditorControlsButton
          onClick={() => {
            dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'EVENT_INFO' }));
          }}
          icon={<Catalog24 />}
          text={_(i18n, 'Event Information')}
        />

        <TreeEditorControlsButton
          icon={<Folder24 />}
          onClick={() => {
            dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'ATTACHMENTS' }));
          }}
          text={_(i18n, 'View Attachments')}
        />
        <TreeEditorControlsButton
          disabled={isViewOnly}
          icon={
            <Cognitive24
              className={classNames({
                'fill--orange grow': hasTemplates && !isViewOnly,
              })}
            />
          }
          onClick={() => dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'TEMPLATES' }))}
          text={_(i18n, 'Analysis Assistant')}
        />
        {/* <TreeEditorControlsButton
          disabled={isViewOnly}
          icon={
            <Time24
              className={classNames({
                'fill--orange grow': hasTemplates && !isViewOnly,
              })}
            />
          }
          onClick={() => dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'REVISIONS' }))}
          text={_(i18n, 'Revision History')}
        /> */}
        {/* Need to figure out the dispatch */}
        <TreeEditorControlsDivider />
        <TreeEditorControlsButton
          onClick={() => {
            dispatch(
              Map({
                type: 'SHOW_TOAST',
                message: 'Generating your image, please wait.',
              }),
            );
            dispatch(
              Map({
                type: 'PNG_START',
                onBlob,
              }),
            );
          }}
          text={_(i18n, 'Download Image')}
          icon={<Image24 />}
        />
        {['someone@example.com'].includes(username) && (
          <TreeEditorControlsButton
            state={state}
            dispatch={dispatch}
            icon={<DocumentDownload24 />}
            onClick={() => {
              dispatch(
                Map({
                  type: 'SHOW_TOAST',
                  message:
                    'Generating your DOCX report. This could take a minute. The file will download automatically when ready. Please do not close the browser until it is finished.',
                }),
              );
              dispatch(Map({ type: 'GENERATE_REPORT' }));
            }}
            text="Download DOCX"
          />
        )}
        <TreeEditorControlsButton
          dispatch={dispatch}
          onClick={() => {
            ReactTooltip.hide();
            history.push(`/report/${treeUuid}`);
          }}
          icon={<Report24 />}
          text={_(i18n, 'View Report')}
        />
      </div>
    </div>
  );
};

export const TreeElements = ({ isViewOnly, isCompleted, state, dispatch }) => {
  const treeUuid = state.getIn(['tree', 'uuid']);
  const mode = state.getIn(['trees', treeUuid, 'methodology']);

  return (
    <div className="h-100 w-100">
      {mode === '5WHYS' ? (
        <FiveWhysTree isViewOnly={isViewOnly} isCompleted={isCompleted} state={state} dispatch={dispatch} />
      ) : mode === 'FISHBONE' ? (
        <FishboneTree isViewOnly={isViewOnly} isCompleted={isCompleted} state={state} dispatch={dispatch} />
      ) : mode === 'PROACT' ? (
        <PROACTTree isViewOnly={isViewOnly} isCompleted={isCompleted} state={state} dispatch={dispatch} />
      ) : (
        <div></div>
      )}
    </div>
  );
};

const TreeEditorLoading = ({ state, dispatch }) => {
  const elements = state.getIn(['tree', 'elements']);
  const [error, setError] = useState(false);
  const [slow, setSlow] = useState(false);

  useEffect(() => {
    if (!elements) {
      const idSlow = setTimeout(() => setSlow(true), 5000);
      const idError = setTimeout(() => {
        setSlow(false);
        setError(true);
      }, 15000);
      return () => {
        clearTimeout(idSlow);
        clearTimeout(idError);
      };
    }
  }, [elements]);

  return (
    <div>
      <HomeNav state={state} dispatch={dispatch} />
      <div className={'fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-1 flex-column'}>
        <div
          className={classNames('justify-center items-center flex-column fadeIn', {
            dn: error,
            flex: !error,
          })}
        >
          <Renew32 className="spin mb2 fill--blue blue" />
          <div className="f2 mb2">Loading Analysis</div>
        </div>
        <div className={classNames('f3 gray fadeIn', { dn: !slow })}>Just a few more seconds.</div>
        <div
          className={classNames('flex-column justify-center items-center fadeIn', {
            flex: error,
            dn: !error,
          })}
        >
          <ErrorOutline32 className="mb2 fill--red red" />
          <div className="f2 mb2">Unable to load this tree.</div>
          <div className="f3">
            <Link className="blue" to="/rcas">
              Click here to return to Analyses.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const TemplateViewNotification = ({ state }) => {
  return (
    <div className="absolute z-2 bg-gold black pa3 flex items-center" style={{ bottom: '2rem', right: '2rem' }}>
      <WarningAlt24 />
      <div className="ml2">You are viewing a template.</div>
    </div>
  );
};

export const TreeEditor = ({ state, dispatch }) => {
  const [model, setModel] = useState(Map());
  const [treeCategories, setTreeCategories] = useState([]);
  const [didFetchTreeCategories, setDidFetchTreeCategories] = useState(false);
  const [didFetchOrgTags, setDidFetchOrgTags] = useState(false);
  // const [didFetchCategories, setDidFetchCategories] = useState(false);
  // const [didFetchTags, setDidFetchTags] = useState(false);
  const didMountRef = useRef();
  const params = useParams();
  const treeUuid = state.getIn(['tree', 'uuid'], params.uuid);
  const ws = !!state.get('ws');
  const isViewOnly = model.get('viewOnly');
  const isLoaded = state.get('tree');
  const categories = state.getIn(['tags', 'categories'], []);
  const treeHasCategories = !!state.getIn(['tree', 'categories']);
  const hasTree = !!state.getIn(['trees', treeUuid]);
  const hasTrees = !!state.get('trees');
  const isTemplate = state.getIn(['trees', treeUuid, 'isTemplate']);
  const isCompleted = state.getIn(['trees', treeUuid, 'status']) === 'COMPLETE';
  const loggedIn = !!state.get('username');
  const token = state.get('token');

  useEffect(() => {
    if (!isLoaded) return;

    // if categories have been fetched but aren't in the state tree yet, add them at state.tree.categories
    if (didFetchTreeCategories && !treeHasCategories && treeCategories.length > 0) {
      dispatch({ type: 'SET_TREE_CATEGORIES', payload: { categories: treeCategories } });
    }
  }, [didFetchTreeCategories, treeCategories, isLoaded, treeHasCategories]);

  useEffect(() => {
    async function fetchOrgTags(t) {
      const results = await getAllTags(t);
      return results;
    }

    async function getCategoriesForTree() {
      const results = await getTreeCategories({ token, treeUuid });
      return results;
    }

    if (!token) {
      console.error('No token available. Skipping tag-fetching useEffect.');
      return;
    }

    if (!didFetchOrgTags) {
      fetchOrgTags(token)
        .then(tags => {
          dispatch({ type: 'SET_CATEGORIES', payload: tags });
          setDidFetchOrgTags(true);
        })
        .catch(e => console.error('failed to fetch tags', e));
    }

    if (!didFetchTreeCategories) {
      getCategoriesForTree()
        .then(c => {
          setTreeCategories(c);
          setDidFetchTreeCategories(true);
        })
        .catch(e => {
          console.error('failed to fetch categories for tree', e);
        });
    }
  }, [didFetchOrgTags, didFetchTreeCategories, token]);

  useEffect(() => {
    if (ws) {
      if (!didMountRef.current) {
        dispatch(Map({ type: 'LIST', treeUuid: treeUuid }));
        dispatch(Map({ type: 'GET_ORGANIZATION' }));

        didMountRef.current = true;
      }
    }

    if (ws && treeUuid && (isCompleted || isTemplate)) {
      if (isTemplate) {
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message: 'Templates cannot be edited. Remove from template library if you need to update it.',
            durationMillis: 300000000,
          }),
        );
      } else if (isCompleted) {
        dispatch(
          Map({
            type: 'SHOW_TOAST',
            message:
              'Completed trees only allow edits to their completed at date. Change the status to update anything else.',
            durationMillis: 300000000,
          }),
        );
      }
      setModel(s => s.set('viewOnly', true));
    }

    if (ws && treeUuid && hasTree) {
      dispatch(Map({ type: 'OPEN_TREE', treeUuid }));

      return () => {
        dispatch(Map({ type: 'CLOSE_TREE', treeUuid }));
        dispatch(Map({ type: 'HIDE_TOAST' }));
      };
    } else if (!ws && treeUuid) {
      initDispatchedWS(null, dispatch, err => {
        if (err) {
          setModel(s => s.set('viewOnly', true));
        }
      });
    }
    // #1326
    // https://github.com/reliability-center/tree/issues/1326
    // this causes viewOnly to be set to false incorrectly
    // ...which causes the cy event handlers to never be attached
    //
    // else if (ws && treeUuid && hasTrees && !hasTree) {
    //   setModel(s => s.set('viewOnly', true));
    // }
  }, [dispatch, treeUuid, ws, hasTree, hasTrees, isCompleted]);

  return (
    <div>
      <ReactTooltip />
      {isLoaded && isLoaded.get('uuid') ? (
        <div>
          <HomeNav state={state} dispatch={dispatch} />
          <div className="fixed left-0 right-0 bottom-0 top-0 fadeIn">
            {/*
            <Presence state={state} dispatch={dispatch} />
            */}
            {isCompleted ? (
              <div className="br2 absolute left-1 bottom-1 ph3 pt1 z-max pb2 bg--black fg--white f6">
                <p className="m0">
                  <Locked16 className="mr2" /> Analysis completed
                </p>
              </div>
            ) : null}

            {/* <div
              style={{ maxWidth: 400, top: 65 }}
              className="flex items-center justify-center br2 absolute right-1  ph3 pt1 z-max pb2 bg--gold fg--black f6 w-third"
            >
              <Warning16 className="w-20 mr2" />
              <p className="lh-copy m0">
                Updating Facilities and Equipment is currently unavailable, we apologize for the inconvenience. <br />A
                resolution is currently underway.
              </p>
            </div> */}

            <TreeEditorControls isViewOnly={isViewOnly} state={state} dispatch={dispatch} />
            <div className="h-100 w-100 relative">
              <NodeInputPopover state={state} dispatch={dispatch} />
              <NodePopover isViewOnly={isViewOnly} state={state} dispatch={dispatch} tags={categories} />
              <TemplateOptions editingDisabled={isCompleted} dispatch={dispatch} state={state} />
              <RevisionHistory dispatch={dispatch} state={state} uuid={treeUuid} />
              <AttachmentOptions editingDisabled={isCompleted} dispatch={dispatch} state={state} />
              {state.getIn(['tree', 'view', 'contextualDrawer']) === 'INFORMATION' ? (
                <NodeAttachmentOptions editingDisabled={isCompleted} dispatch={dispatch} state={state} />
              ) : null}
              {state.getIn(['tree', 'view', 'contextualDrawer']) === 'EVENT_INFO' ? (
                <EventInfoOptions editingDisabled={isCompleted} dispatch={dispatch} state={state} />
              ) : null}
              <TreeElements
                editingDisabled={isCompleted || isTemplate}
                isViewOnly={isViewOnly}
                state={state}
                dispatch={dispatch}
              />
              {isTemplate && <TemplateViewNotification state={state} />}
            </div>
          </div>
        </div>
      ) : isViewOnly ? (
        <TreeViewer loggedIn={loggedIn} />
      ) : (
        <TreeEditorLoading state={state} dispatch={dispatch} />
      )}
    </div>
  );
};
