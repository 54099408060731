import { useEffect, useState } from 'react';
import { Map } from 'immutable';
import { format } from 'date-fns';
import _ from 'lodash';
import { createTreeFromRevision, getTreeRevisions } from 'Sync';
import classNames from 'classnames';

import { Spinner } from 'components';
import { RevisionItem } from './RevisionItem';
import { Blade } from './Blade';

export const RevisionHistory = ({ state, dispatch, uuid }) => {
  const [revisionHistory, setRevisionHistory] = useState(null);
  const [selectedRevision, setSelectedRevision] = useState(null);
  const [isCreatingFromRevision, setIsCreatingFromRevision] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!uuid) return;

    async function fetchRevisions() {
      setIsLoading(true);
      setError(null);

      try {
        const revisions = await getTreeRevisions({
          treeUuid: uuid,
        });
        setRevisionHistory(revisions);
      } catch (error) {
        console.error('Failed to fetch tree revisions:', error);
        setError('Failed to load revision history. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }

    fetchRevisions();
  }, [uuid]);

  const handleCreateTreeFromRevision = async revision => {
    setIsCreatingFromRevision(true);
    setSelectedRevision(revision);

    try {
      const result = await createTreeFromRevision(revision);

      window.open(`/#/tree/${result.data.treeUuid}`, '_blank');
    } catch (error) {
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: 'Failed to create tree from revision',
          style: 'ERROR',
        }),
      );
    } finally {
      setIsCreatingFromRevision(false);
    }
  };

  const formatRevisions = revisions => {
    if (!revisions?.data?.rows?.length) return {};

    return _.groupBy(revisions.data.rows, revision => {
      const date = new Date(revision.createdAt);
      return format(date, 'MM/dd/yy');
    });
  };

  const formatTime = dateString => {
    const date = new Date(dateString);
    return format(date, 'h:mma');
  };

  const isToday = dateStr => {
    const today = format(new Date(), 'MM/dd/yy');
    return dateStr === today;
  };

  const groupedRevisions = formatRevisions(revisionHistory);

  const handleOnCancel = () => {
    return setSelectedRevision(null);
  };

  return (
    <Blade
      title="Revision History"
      show={state.getIn(['tree', 'view', 'contextualDrawer']) === 'REVISIONS'}
      state={state}
      dispatch={dispatch}
    >
      <div className={classNames('items-center mh3 pl2 pr2 pt2')}>
        {isLoading && (
          <div className="flex items-center justify-center h3">
            <Spinner />
          </div>
        )}

        {error && (
          <div className="pa3 mb3 red br2">
            <span className="f6">{error}</span>
          </div>
        )}

        {!isLoading && !error && (
          <>
            {Object.entries(groupedRevisions)
              .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
              .map(([date, revisions]) => (
                <div key={date} className="mb-0">
                  <h4 className="f6 white fw6 mb2">{isToday(date) ? 'Today' : date}</h4>
                  <div className="pt1">
                    {revisions
                      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                      .map(revision => {
                        const handleOnClick = () => {
                          setSelectedRevision(revision.revUuid);
                        };

                        const handleOnConfirm = () => {
                          return handleCreateTreeFromRevision(revision.revUuid);
                        };

                        return (
                          <RevisionItem
                            disabled={isCreatingFromRevision && revision.revUuid !== selectedRevision}
                            key={revision.revUuid}
                            loading={selectedRevision === revision.revUuid && isCreatingFromRevision}
                            time={formatTime(revision.createdAt)}
                            author={revision.author}
                            onClick={handleOnClick}
                            //
                            onConfirm={handleOnConfirm}
                            onCancel={handleOnCancel}
                            //
                          />
                        );
                      })}
                  </div>
                </div>
              ))}
            {!revisionHistory?.data?.rows?.length && <div className="gray mt2">None found</div>}
          </>
        )}
      </div>
    </Blade>
  );
};

export default RevisionHistory;
